
import "./checkout-tos-v1"
import "./checkout-order-summary-v1"
import "./checkout-login-v2"
import "./checkout-product-select-v2"
import "./checkout-product-select-bump-v2"
import "./checkout-multiple-payments-v2"
import "./checkout-express-payments-v1"
import "./checkout-multi-step-v2"
import "./checkout-contact-form-v1"
import "./checkout-billing-address-v1"
import "./checkout-shipping-address-v1"
import "./checkout-saved-contact-details-v1"
import "./checkout-saved-billing-address-v1"
import "./checkout-shipping-profile-select-v1"
import "./checkout-saved-multiple-payments-v1"
import "./checkout-submit-button-v1"
import "./checkout-threeds-frame-v1"
import "./checkout-configuration-error-v1"
import "./checkout-loading-v1"
import "./checkout-order-details-v1"
import "@yggdrasil/Checkout/V2/RebillyCheckout/checkout-globals"
import { CheckoutSummary } from '@yggdrasil/Checkout/V2/Services/checkout-summary'
import { create, buildComputed, initializeUtilities } from '@yggdrasil/Checkout/V2/Services/checkout-store'
import { scrollToFirstVisibleError, collectLeads } from '@yggdrasil/Checkout/V2/Services/checkout-utils'
import { initializeMachine } from '@yggdrasil/Checkout/V2/Services/checkout-machine'
import { loadPAIDeps } from '@yggdrasil/Checkout/V2/Services/checkout-machine'
import { CheckoutSubmit } from "@yggdrasil/Checkout/V2/Services/checkout-submit"
import { formBySteps } from '@yggdrasil/Checkout/V2/CheckoutMultiStep/V2/checkoutFormUtils'
import { computed } from 'nanostores'
import { setupOrderSummaries } from "@yggdrasil/Checkout/V2/Services/checkout-utils"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class RebillyCheckout extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}












  FORM_STRUCTURE_BY_TOTAL_STEPS = {
    2: [['contact', 'shipping'], ['products', 'shippingOption', 'billing', 'payment', 'tos']],
    3: [['products'], ['contact', 'shipping'], ['shippingOption', 'billing', 'payment', 'tos']]
  }

  FORM_BY_STEP = formBySteps(this.FORM_STRUCTURE_BY_TOTAL_STEPS)

  initialize() {
    // TODO(henrique): <ONE_STORE_PER_CHECKOUT> right now we are saving store as a global object
    // but the goal is having one store per checkout.
    Checkout.isCheckoutResource = globalThis.globalResourceData.resourceName == "checkout"
    const billingFields = this.billingEnabled ? this.billingFields : []
    globalResourceData.enabledPayments = !window.ApplePaySession ? globalResourceData.enabledPayments.filter((v) => v != 'apple-pay') : globalResourceData.enabledPayments
    globalResourceData.expressEnabledPayments = !window.ApplePaySession ? globalResourceData.expressEnabledPayments.filter((v) => v != 'apple-pay') : globalResourceData.expressEnabledPayments
    if (!globalResourceData.expressEnabledPayments.includes("apple-pay")) {
      (this.element.querySelectorAll('.elExpressCheckoutForCart') ?? []).forEach((item) => item.remove())
    }
    Checkout.store = create(billingFields, this.shippingFields, globalResourceData.enabledPayments, globalResourceData.expressEnabledPayments)
    Checkout.computed = buildComputed(Checkout.store, this.contactFields, this.shippingFields, this.totalSteps)
    initializeUtilities(Checkout.store, Checkout.computed)
    initializeMachine({
      hasPhoneNumber: this.contactFields.includes('phone_number'),
      billingFields: billingFields,
      shippingFields: this.shippingFields,
    })
  }

  backfillContactDataFromGarlic_Mutable(contactData) {
    Object.keys(contactData).reduce((acc, field) => {
      if (!contactData[field]) {
        const val = window.cfGarlicUtils.retrieve(field)
        if (field?.length) acc[field] = val
      }
      return acc
    }, contactData)
  }

  updateCheckoutMode(mode) {
    const containerMapping = Object.values(Checkout.CheckoutStates).reduce((acc, item) => {
      acc[item] = this.element.querySelector(`[data-wrapper-checkout-state="${item}"]`)
      return acc
    }, {})

    const currentContainer = containerMapping[mode]

    for (let container of Object.values(containerMapping)) {
      if (container == currentContainer) {
        currentContainer.classList.remove('elHide')
      } else {
        container.classList.add('elHide') 
      }
    }
  }

  mount() {
    const contactData = this.contactFields.reduce((acc, field) => {
      acc[field] = Checkout.initialContactData[field]
      return acc
    }, {})
    this.backfillContactDataFromGarlic_Mutable(contactData)

    ;[Checkout.computed.checkoutCart, Checkout.store.billing, Checkout.store.shipping, Checkout.store.shippingOption].forEach((store) => {
      store?.listen(() => {
        CheckoutSummary.sendOrderPreview()
      })
    })

    if (!this.disableOTP) {
      Checkout.auth.computed.requireLogin.listen(requireLogin => {
        if (requireLogin) {
          // hide checkout form
          this.element.querySelector(`[data-wrapper-checkout-state="${Checkout.store.checkout.mode.get()}"]`)
            .classList.add('elHide')
  
        } else {
          // restore checkout form
          this.updateCheckoutMode(Checkout.store.checkout.mode.get())
        }
      })
    }
    Checkout.store.checkout.mode.listen((mode) => {
      this.updateCheckoutMode(mode)
    })
    
    this.listenAndUpdateCheckoutErrors()
    
    // TODO: checkout component could have its local store so that if/when we support multiple checkouts,
    // the value we store here doesnt conflict with other checkout elements.
    Checkout.store.tos.present.set(this.showTos);

    Checkout.store.state.listen((state) => {
      if ([Checkout.StoreStates.START, Checkout.StoreStates.INITIALIZING, Checkout.StoreStates.INITIALIZED].includes(state)) {
        this.element.querySelector('.elSpinnerWrapper').dataset.loader = true
        if (state === Checkout.StoreStates.INITIALIZED) {
          Checkout.store.contact.set(contactData)
        }
      } else {
        if (this.element.querySelector('[data-page-element="CheckoutConfigurationError/V1"]').querySelector('.elHide')) {
          delete this.element.querySelector('.elSpinnerWrapper').dataset.loader 
        }
      }
    })

    this.element.querySelectorAll('[href="#!checkout-as-guest"]').forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault()
        Checkout.store.checkout.mode.set('guest')
      })
    })


    Checkout.computed.hasShippingEnabled.subscribe(hasShippingEnabled => {   
      if (!hasShippingEnabled && Checkout.store.shippingOption.value) {
        // clear any selected shipping option if shipping is no longer enabled
        Checkout.store.shippingOption.set(null)
      }
    })
    Checkout.computed.hideShipping.subscribe(hideShipping => {
      this.element.querySelector('.elCheckoutWrapper').setAttribute('data-shipping-enabled', hideShipping ? 'false' : 'true')      
    })

    // Initialize checkout after all children components are mounted
    Checkout.store.state.set(Checkout.StoreStates.INITIALIZING)

    setupOrderSummaries(this)

    this.element.querySelectorAll('input, select').forEach((el) => {
      el.addEventListener('focus', () => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })

    const trackableStores = [
      Checkout.store.contact,
      Checkout.store.shipping,
      Checkout.store.billing,
      Checkout.store.billingSameAsShipping,
      Checkout.store.tos.accepted,
      Checkout.store.shippingOption,
      // Checkout.store.payment.state,
      Checkout.store.payment.id,
      Checkout.store.payment.type,
      Checkout.store.payment['payment-card'].events,
      Checkout.store.payment['payment-card'].token,
      Checkout.store.payment.paypal.state,
      Checkout.store.payment.paypal.token,
      Checkout.computed.checkoutCart,
    ]
    trackableStores.forEach((store) => {
      store.listen((v) => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })

    Checkout.computed.isOTORetrialPayment.listen((isOTORetrialPayment) => {
      if (isOTORetrialPayment) {
        this.element.querySelector('.elCheckoutOtoPayment').classList.remove('elHide')
      }
    })

    this.setHandlers(CheckoutMultiStepV2, {
      onValidateStep: (step) => {
        const valid = this.validateStep(step)
        if(!valid) {
          scrollToFirstVisibleError()
        }
        return valid
      },
      onStepChange: (oldStep, currentStep) => {
        const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStep]
        if (currentFormStructure.includes('payment')) {
          loadPAIDeps()
        }
        const oldFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][oldStep]
        if (oldFormStructure.includes('contact')) {
          const contactDetails = Checkout.store.contact.get()
          collectLeads(contactDetails)
        }
      }
    })


    this.setHandlers(CheckoutSubmitButtonV1,{
      onSubmit: (element) => {
        CheckoutSubmit.submitFromButtonClick(element, () => scrollToFirstVisibleError())
      }
    })

    this.setHandlers(CheckoutTosV1, {
      onAcceptedChange: (accepted) => {
        Checkout.store.tos.accepted.set(accepted)
      }
    })

    // this.setHandlers(CheckoutCouponFormV1,{
    //   onApplyCoupon: (options) => {
    //     // NOTE: <ONE_STORE_PER_CHECKOUT> this should be stored per checkout object store
    //     CheckoutSummary.sendOrderPreview(options)
    //   }
    // })
    window.addEventListener('ON_APPLY_COUPON', (event: CustomEvent) => {
      CheckoutSummary.sendOrderPreview(event.detail)
    })

    window.addEventListener('ON_CLEAR_COUPON', (event: CustomEvent) => {
      CheckoutSummary.sendOrderPreview(event.detail)
    })

    this.setupShippingProfileSelect()
    this.setupSubmittingListeners()
  }

  setupShippingProfileSelect() {
    const shippingProfileSelects = this.getComponentGroup(CheckoutShippingProfileSelectV1, {
      onSelectOption: (option) => {
        Checkout.store.shippingOption.set(option)
      }
    })
    
    const renderDeps = computed([
      Checkout.store.shippingOptions, 
      Checkout.computed.hasValidShippingAddress,
      Checkout.store.loadingShipping,
      Checkout.store.summary,

    ], (...args) => args)

    renderDeps.listen(([shippingOptions, hasValidShippingAddress, loadingShipping, summary]) => {
      const shippingOption = Checkout.store.shippingOption.get()
      const summaryHasError = summary.state === 'error'
      shippingProfileSelects.update({shippingOption, shippingOptions, hasValidShippingAddress, loadingShipping, summaryHasError})
    })

    Checkout.store.shippingOption.listen(shippingOption => {
      shippingProfileSelects.setOption(shippingOption)
    })  

    Checkout.computed.isNewDigitalWalletPayment.subscribe(isNewDigitalWalletPayment => {
      if (isNewDigitalWalletPayment) {
        shippingProfileSelects.hide()
      } else {
        shippingProfileSelects.show()
      }
    })

  }
  
  setupSubmittingListeners() {
    const checkoutLoading = this.getComponentGroup(CheckoutLoadingV1)
    const submitButtons = this.getComponentGroup(CheckoutSubmitButtonV1)
    const submitNotification = this.getComponentGroup(CheckoutSubmitNotificationV1)

    computed([Checkout.store.submitting, Checkout.store.summary], (submitting, summary) => {
      const state = submitting.state
      switch(state) {
        case Checkout.SubmittingStates.IDLE: {
          checkoutLoading.hide()
          switch (summary.state) {
            case Checkout.SummaryStates.CALCULATING: {
              submitButtons.setLoading()
              break;
            }
            default: {
              submitButtons.setButtonInitialHTML()
              submitNotification.setStatus("", "")
            }
          }

          break;
        }
        case Checkout.SubmittingStates.START: {
          checkoutLoading.show()
          submitButtons.setSubmittingText()
          submitNotification.setStatus("", "")
          break;
        }
        case Checkout.SubmittingStates.WAITING_ON_QUEUE: {
          checkoutLoading.show()
          checkoutLoading.showWaitingOnQueueText(`Waiting on queue (Retrying in ${submitting.remainingSeconds}s)`)
          submitNotification.setStatus('Waiting on queue', `(Retrying in ${submitting.remainingSeconds}s)`)
          break;
        }
        case Checkout.SubmittingStates.ERROR: {
          checkoutLoading.hide()
          submitButtons.setButtonInitialHTML()

          switch (submitting.code) {
            case Checkout.ErrorTypes.REBILLY_ERROR:
            case Checkout.ErrorTypes.SERVER_ERROR:
            case Checkout.ErrorTypes.UNHANDLED_SERVER_RESPONSE:
            case Checkout.ErrorTypes.EXCEEDED_MAX_RETRIES: {
              submitNotification.setStatus('Failed to submit', 'Retry again in a few seconds', submitting.message ? submitting.message : "")
              break;
            }
            case Checkout.ErrorTypes.THREEDS_DECLINED_ERROR: {
              submitNotification.setStatus('Failed to submit', 'Transaction declined')
              break;
            }
            case Checkout.ErrorTypes.SERVER_ERROR_WITH_MESSAGE: {
              submitNotification.setStatus('Failed to submit', submitting.message)
              break;
            }
            case Checkout.ErrorTypes.THREEDS_DECLINED_CUSTOM_ERROR: {
              submitNotification.setStatus('Failed to submit', submitting.message)
              break;
            }
          }
          break;
        }
      }
    }).subscribe(() => {})
  }

  validateStep(step) {
    const currentStep = Checkout.store.checkout.step.get()
    const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStep]
    
    const errors = currentFormStructure.reduce((acc, formName) => {
      Checkout.store.showAllErrors[formName].set(true)

      const error = Checkout.computed.errorsByName[formName].get()
      if (Checkout.utils.hasErrors(error)) {
        acc.push(error)
      }
      return acc
    }, [])

    return errors.length == 0
  }

  listenAndUpdateCheckoutErrors() {
    Object.entries(Checkout.computed.errorsByName).forEach(([name, computed]) => {
      computed.subscribe((error) => {
        Object.values(Checkout.CheckoutStates).forEach((mode) => {
          let formStructure = []
          let currentErrorElement = null
          if (mode == 'guest' && this.totalSteps != 1) {
            const currentStep = Checkout.store.checkout.step.get()
            let currentStepWithError = currentStep
            currentErrorElement = this.element.querySelector('.elMultiStepBody[data-step-state="active"]').querySelector('.checkout-general-errors-wrapper')
            const stepForField = this.FORM_BY_STEP[this.totalSteps][name]
            if (stepForField != undefined) {
              if (Checkout.utils.hasErrors(error) && stepForField < currentStep) {
                Checkout.store.checkout.step.set(stepForField)
                scrollToFirstVisibleError()
              }
              currentErrorElement = this.element.querySelector(`.elMultiStepBody[data-step-number="${stepForField + 1}"]`).querySelector('.checkout-general-errors-wrapper')
              currentStepWithError = stepForField
            }
            formStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStepWithError]

          } else {
            formStructure = ['shippingOption', 'products', 'tos', 'payment', 'billing']
            currentErrorElement = this.element.querySelector(`[data-wrapper-checkout-state="${mode}"]`).querySelector('.checkout-general-errors-wrapper')
          }
          
          const errors = formStructure.reduce((acc, formName) => {
            const error = Checkout.computed.errorsByName[formName].get()
            if (Checkout.utils.hasErrors(error)) {
              acc.push({name: formName, errors: error})
            }
            return acc
          }, [])
          if (!errors || errors?.length == 0) {
            currentErrorElement.classList.add('elHide')
          } else {
            const error = errors[0]
            if (error.errors?.globalErrors?.[0]?.message) {
              const errorMessage = error.errors.globalErrors[0].message
              currentErrorElement.querySelector('span').innerHTML = errorMessage
              currentErrorElement.classList.remove('elHide')
            } else {
              currentErrorElement.classList.add('elHide')
            }
          }
        })
      })
    })
  }

  collectLeads() {
    
  }



}

registerComponent('RebillyCheckout', RebillyCheckout)
window["RebillyCheckout"] = RebillyCheckout

