import { deepEqual, formatNumberToCurrency } from 'src/Elements/Utils/general'

import { isSavedAddress } from '../Utils/checkout-address'

import { Address, CheckoutCart } from 'src/Elements/Checkout/V2/types'
import { SummaryLineItem, SummaryData, ServerAddressAttributes, ShippingOption } from '../types'
import { ERROR_CODES } from './checkout-critical-errors'
import { hasPhysicalProducts } from './checkout-utils'
import { CartData } from 'src/Elements/Cart/types'
import { makeCurrentPageFetcher } from '../Utils/api'
import { isResponseError, isBackendError, isFetcherError, MANUALLY_ABORTED } from 'javascript/lander/utils/fetcher'

type CreateTransactionData = {
  currency: string
  amount: number
  label: string
  lineItems: { label: string; amount: string | number }[]
  requestShipping: boolean
}

type ServerReqSummaryAddresses = {
  billing_address?: ServerAddressAttributes
  delivery_address?: ServerAddressAttributes
}

type ServerReqSummaryLineItem = {
  line_item_id?: number
  variant_id: number
  price_id: number
  quantity: number
}

type ServerReqSummaryCart = {
  order_id: number
  line_items: ServerReqSummaryLineItem[]
}

type ServerReqSummary = {
  order: {
    full_preview: boolean
    should_backfill_billing_address: boolean
    coupon_codes: string | string[]
    selected_shipping_option?: ShippingOption
  } & ServerReqSummaryAddresses &
    ServerReqSummaryCart
}

class PreviewError extends Error {
  details?: string
  constructor(message: string, details?: string) {
    super(message)
    this.name = this.constructor.name
    this.details = details
    if (Error.captureStackTrace) Error.captureStackTrace(this, this.constructor)
  }
}

export type ServerRespSummaryData = {
  currency: string
  currency_symbol: string
  line_items: SummaryLineItem[]
  billing_address: { city: string; region: string }
  shipping_quotes_response: any
  selected_shipping_option: any
  total_amount: {
    amount: string
  }
  total_amount_formatted: string
  discount_amount: {
    amount: string
  }
  discount_amount_formatted: string
  discounts: { name: string; code: string; amount: number }[]
  subtotal_amount: {
    amount: string
  }
  subtotal_amount_formatted: string
  shipping_amount: {
    amount: string
  }
  shipping_amount_formatted: string
  tax_amount: {
    amount: string
  }
  tax_amount_formatted: string
  upcoming_invoice?: {
    interim_amount: {
      amount: string
    }
    interim_amount_formatted: string
    line_items: {
      variant_name: string
      quantity: number
      amount: {
        amount: string
      }
      interim: boolean
      amount_formatted: string
    }[]
  }
}

const orderPreviewFetcher = makeCurrentPageFetcher<ServerRespSummaryData>()

export class CheckoutSummary {
  static #sendOrderPreviewTimer
  static #lastOrderPreviewData
  static #lastRebillyUpdate
  static #couponStateBeforeApplying
  static #skipOrderSummaryUpdate

  static #setLoadingShippingOptions(dataToPreview: ServerReqSummary): void {
    // NOTE: if the  only data changed was shippingOption, we dont want to shipping loading state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selected_shipping_option: _tmpShippingOption1, ...dataToPreviewWithoutShippingOption } =
      dataToPreview?.order ?? {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selected_shipping_option: _tmpShippingOption2, ...lastOrderPreviewDataWithoutShippingOption } =
      this.#lastOrderPreviewData?.order ?? {}

    if (!deepEqual(dataToPreviewWithoutShippingOption, lastOrderPreviewDataWithoutShippingOption)) {
      globalThis.Checkout.store.loadingShipping.set(true)
    }
  }

  static skipOrderSummaryUpdateWithCb(cb: () => void): void {
    this.#skipOrderSummaryUpdate = true
    cb()
    this.#skipOrderSummaryUpdate = false
  }

  static sendOrderPreview(options?: { isCouponValidation?: boolean }): void {
    const cart = globalThis.Checkout.computed.checkoutCart.get() as CheckoutCart
    const isCouponValidation = !!options?.isCouponValidation
    if (this.#skipOrderSummaryUpdate) {
      return
    }
    const billingFields = globalThis.Checkout.store.billingFields?.get()
    const shippingFields = globalThis.Checkout.store.shippingFields?.get()
    const checkoutResourcePage = globalThis.globalResourceData.resourceName == 'checkout'

    // No need for address fields when applying coupon or when checkout resource page
    const shouldUpdateSummaryData =
      isCouponValidation ||
      checkoutResourcePage ||
      globalThis.Checkout.utils.hasValidDataForOrderPreview({ billingFields, shippingFields })

    const hasDigitalPayment =
      globalThis.globalResourceData.enabledPayments.includes('apple-pay') ||
      globalThis.globalResourceData.expressEnabledPayments.includes('apple-pay')

    if (!shouldUpdateSummaryData && !hasDigitalPayment) {
      if (globalThis.Checkout.store.summary.get().state != globalThis.Checkout.SummaryStates.WAITING) {
        globalThis.Checkout.store.summary.set({ state: globalThis.Checkout.SummaryStates.WAITING })
      }
      this.#lastOrderPreviewData = null
      return
    }

    const dataToPreview = this.#buildOrderPreviewPayload(
      cart,
      {
        billingAddress: globalThis.Checkout.store.billing.get(),
        shippingAddress: globalThis.Checkout.store.shipping.get(),
        shippingOption: globalThis.Checkout.store.shippingOption.get(),
        billingSameAsShipping: globalThis.Checkout.store.billingSameAsShipping.get(),
      },
      {
        skipBillingAddress: globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store),
      }
    )

    if (!isCouponValidation && deepEqual(dataToPreview, this.#lastOrderPreviewData)) {
      return
    }

    if (this.#sendOrderPreviewTimer) clearTimeout(this.#sendOrderPreviewTimer)

    if (globalThis.Checkout.store.summary.get().state != globalThis.Checkout.SummaryStates.CALCULATING) {
      if (globalThis.Checkout.store.payment.type.get() == 'apple-pay') {
        globalThis.Checkout.store.isUpdatingRebilly.set(true)
      }
      if (shouldUpdateSummaryData) {
        globalThis.Checkout.store.summary.set({ state: globalThis.Checkout.SummaryStates.CALCULATING })
      }
    }

    const couponStateBeforeApplying = globalThis.Checkout.store.coupons.state.get()
    if (isCouponValidation && couponStateBeforeApplying != globalThis.Checkout.CouponStates.APPLYING) {
      this.#couponStateBeforeApplying = couponStateBeforeApplying
      globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.APPLYING)
    }

    const validShippingAddress = !!globalThis.Checkout.utils.hasValidDataForShippingOptions({ shippingFields })
    const validBillingAddress = !!globalThis.Checkout.utils.hasValidDataForTaxes({ billingFields })
    if (validShippingAddress) {
      this.#setLoadingShippingOptions(dataToPreview)
    }

    clearTimeout(this.#sendOrderPreviewTimer)
    this.#sendOrderPreviewTimer = setTimeout(() => {
      return this.fetchOrderPreview(cart, {
        shouldSetShippingOptions: validShippingAddress,
        shouldSetTax: validBillingAddress,
        shouldUpdateSummaryData,
      })
    }, 500)
  }

  static async fetchOrderPreview(
    cart: CheckoutCart,
    options: {
      shouldSetShippingOptions?: boolean
      shouldSetTax?: boolean
      shouldUpdateSummaryData?: boolean
    }
  ): Promise<void> {
    options = options ?? {}
    const shouldUpdateSummaryData = options.shouldUpdateSummaryData ?? true
    const dataToPreview = this.#buildOrderPreviewPayload(
      cart,
      {
        billingAddress: globalThis.Checkout.store.billing.get(),
        shippingAddress: globalThis.Checkout.store.shipping.get(),
        shippingOption: globalThis.Checkout.store.shippingOption.get(),
        billingSameAsShipping: globalThis.Checkout.store.billingSameAsShipping.get(),
      },
      {
        skipBillingAddress: globalThis.Checkout.utils.skipBillingAddress(globalThis.Checkout.store),
      }
    )
    this.#lastOrderPreviewData = dataToPreview

    // Clear recurring mismatch error before the next preview
    const criticalErrors = globalThis.Checkout.store.criticalErrors.get()
    globalThis.Checkout.store.criticalErrors.set([
      ...criticalErrors.filter((error) => error.code != ERROR_CODES.ORDER_RECURRING_INTERVAL_MISMATCH),
    ])
    const summaryData = await orderPreviewFetcher.post('/order_preview', dataToPreview)
    if (isResponseError(summaryData)) {
      if (isFetcherError(summaryData)) {
        if (summaryData.error != MANUALLY_ABORTED) {
          const errorData = summaryData
          throw new PreviewError(errorData.error)
        }
      } else if (isBackendError(summaryData)) {
        globalThis.Checkout.store.loadingShipping.set(false)
        const error = summaryData.error.details?.[0]
        let newSummaryState
        if (error?.type == 'InvalidPostalCodeError') {
          newSummaryState = {
            state: globalThis.Checkout.SummaryStates.WAITING,
          }
          globalThis.Checkout.store.showAllErrors.billing.set(true)
          globalThis.Checkout.store.billingApiErrorsByField.set({ zip: { message: error.message } })
          this.disableIsUpdatingRebilly()
        } else if (error?.type == 'InvalidCouponError') {
          globalThis.Checkout.store.coupons.appliedCode.set('')
          globalThis.Checkout.store.coupons.errorMessage.set(error.message)
          globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.ERROR)
          const summaryData = this.#processOrderPreviewResponseForSummary(error.preview, {
            shouldSetShippingOptions: options?.shouldSetShippingOptions,
            shouldSetTax: options?.shouldSetTax,
          })
          newSummaryState = {
            state: globalThis.Checkout.SummaryStates.OK,
            data: summaryData,
          }
          this.updateRebillyTransactionData(summaryData, cart)
        } else {
          globalThis.Checkout.store.coupons.state.set(this.#couponStateBeforeApplying)
          newSummaryState = { state: globalThis.Checkout.SummaryStates.ERROR }
          if (error?.type == 'RecurringMismatch') {
            const criticalErrors = globalThis.Checkout.store.criticalErrors.get()
            globalThis.Checkout.store.criticalErrors.set([
              ...criticalErrors,
              { code: ERROR_CODES.ORDER_RECURRING_INTERVAL_MISMATCH },
            ])
          }
          this.disableIsUpdatingRebilly()
        }

        if (shouldUpdateSummaryData && newSummaryState) {
          globalThis.Checkout.store.summary.set(newSummaryState)
        }
      }
    } else {
      const { shipping_quotes_response, selected_shipping_option } = summaryData

      // NOTE: avoids infinite loop
      if (options?.shouldSetShippingOptions) {
        this.skipOrderSummaryUpdateWithCb(() => {
          globalThis.Checkout.store.shippingOptions.set(shipping_quotes_response?.options ?? [])
          globalThis.Checkout.store.shippingOption.set(selected_shipping_option)
          globalThis.Checkout.store.loadingShipping.set(false)
          this.#backfillBillingAddress(summaryData)
        })
      }

      const currentCode = globalThis.Checkout.store.coupons.currentCode.get()
      const couponCodeApplied = summaryData.discounts?.find(
        (discount) => discount.code?.toUpperCase() == currentCode?.toUpperCase()
      )
      if (couponCodeApplied) {
        globalThis.Checkout.store.coupons.appliedCode.set(currentCode)
        globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.APPLIED)
      } else {
        globalThis.Checkout.store.coupons.state.set(globalThis.Checkout.CouponStates.READY)
      }

      const newSummaryState = {
        state: globalThis.Checkout.SummaryStates.OK,
        data: this.#processOrderPreviewResponseForSummary(summaryData, {
          shouldSetShippingOptions: options?.shouldSetShippingOptions,
          shouldSetTax: options?.shouldSetTax,
        }),
      }
      if (shouldUpdateSummaryData) {
        globalThis.Checkout.store.summary.set(newSummaryState)
      }
      this.updateRebillyTransactionData(newSummaryState.data, cart)
    }
  }

  static async fetchOrderSummaryForExpress(
    cart: CheckoutCart,
    payload: {
      billingAddress: Address
      shippingAddress: Address
      shippingOption: ShippingOption
      billingSameAsShipping: boolean
    }
  ): Promise<{ summary?: ServerRespSummaryData; data?: SummaryData }> {
    const dataToPreview = this.#buildOrderPreviewPayload(
      cart,
      {
        billingAddress: payload.billingAddress,
        shippingAddress: payload.shippingAddress,
        shippingOption: payload.shippingOption,
        billingSameAsShipping: payload.billingSameAsShipping,
      },
      {
        skipBillingAddress: false,
      }
    )
    const summaryData = await orderPreviewFetcher.post('/order_preview', dataToPreview)
    if (isResponseError(summaryData)) {
      if (isFetcherError(summaryData)) {
        throw new PreviewError(summaryData.error)
      } else if (isBackendError(summaryData)) {
        throw new PreviewError(summaryData.error.error, summaryData.error.details)
      }
    } else {
      const validShippingAddress = this.#validAddress(payload.shippingAddress)
      const validBillingAddress = this.#validAddress(payload.billingAddress)

      return {
        summary: summaryData,
        data: this.#processOrderPreviewResponseForSummary(summaryData, {
          shouldSetShippingOptions: validShippingAddress,
          shouldSetTax: validBillingAddress,
        }),
      }
    }
  }

  static #validAddress(address: Address): boolean {
    return !!address?.zip && !!address?.country
  }

  static #backfillBillingAddress(summaryData: ServerRespSummaryData): void {
    const checkoutBilling = globalThis.Checkout.store.billing.get()
    if (summaryData.billing_address) {
      const { city: summaryCity, region: summaryRegion } = summaryData.billing_address
      if (!checkoutBilling.city || !checkoutBilling.state) {
        globalThis.Checkout.store.billing.set({
          ...checkoutBilling,
          ...(!checkoutBilling.city ? { city: summaryCity } : {}),
          ...(!checkoutBilling.state ? { state: summaryRegion } : {}),
        })
      }
    }
  }

  static #processOrderPreviewResponseForSummary(
    summaryData: ServerRespSummaryData,
    options?: { shouldSetShippingOptions?: boolean; shouldSetTax?: boolean }
  ): SummaryData {
    return {
      currency: summaryData.currency,
      line_items: summaryData.line_items,
      total: {
        amount: parseFloat(summaryData.total_amount?.amount),
        formatted: summaryData.total_amount_formatted,
      },
      discount: {
        amount: parseFloat(summaryData.discount_amount.amount),
        formatted: summaryData.discount_amount_formatted,
      },
      discounts: (summaryData.discounts ?? []).map((discount) => {
        return {
          name: discount.name,
          code: discount.code,
          amount_formatted: '-' + formatNumberToCurrency(summaryData.currency_symbol, discount.amount),
        }
      }),
      subtotal: {
        amount: parseFloat(summaryData.subtotal_amount.amount),
        formatted: summaryData.subtotal_amount_formatted,
      },
      ...(options?.shouldSetShippingOptions
        ? {
            shipping: {
              amount: parseFloat(summaryData.shipping_amount.amount),
              formatted: summaryData.shipping_amount_formatted,
            },
          }
        : { shipping: {} }),
      ...(options?.shouldSetTax
        ? {
            tax: {
              amount: parseFloat(summaryData.tax_amount.amount),
              formatted: summaryData.tax_amount_formatted,
            },
          }
        : { tax: {} }),
      ...(summaryData.upcoming_invoice?.interim_amount
        ? {
            interim: {
              amount: parseFloat(summaryData.upcoming_invoice.interim_amount.amount),
              formatted: summaryData.upcoming_invoice.interim_amount_formatted,
            },
          }
        : {}),
      ...(summaryData.upcoming_invoice
        ? {
            upcoming_invoice: {
              line_items: summaryData.upcoming_invoice.line_items
                .filter((i) => i.interim)
                .map((item) => {
                  return {
                    variant_name: item.variant_name,
                    quantity: item.quantity,
                    amount: parseFloat(item.amount.amount),
                    formatted: item.amount_formatted,
                  }
                }),
            },
          }
        : {}),
    }
  }

  static #buildCartDetails(cart: CheckoutCart): ServerReqSummaryCart {
    // TODO: I may not to user order_id for reactivations as in this case we will
    // wanna create a new order for now
    const orderId = cart.order_id
    const lineItems = cart.items.map(({ price_id, line_item_id, variant_id, quantity }) => {
      const data: ServerReqSummaryLineItem = {
        variant_id: variant_id,
        price_id: price_id,
        ...(line_item_id ? { line_item_id: line_item_id } : {}),
        quantity,
      }
      return data
    })
    return { order_id: orderId, line_items: lineItems }
  }

  static #buildOrderPreviewPayload(
    cart: CartData,
    payload: {
      billingAddress: Address
      shippingAddress: Address
      shippingOption: ShippingOption
      billingSameAsShipping: boolean
    },
    options: { skipBillingAddress: boolean }
  ): ServerReqSummary {
    // NOTE: currentCode is manily applied in case of code change and clicking Apply button
    // appliedCode is used in all subsequent requests - eg. when product is changed after coupon is applied
    const couponState = globalThis.Checkout.store.coupons.state.get()
    let couponCode
    if (couponState == globalThis.Checkout.CouponStates.APPLYING) {
      couponCode = globalThis.Checkout.store.coupons.currentCode.get()
    } else {
      couponCode = globalThis.Checkout.store.coupons.appliedCode.get()
    }
    const selected_shipping_option = payload.shippingOption
    return {
      order: {
        full_preview: true,
        should_backfill_billing_address: true,
        coupon_codes: couponCode,
        ...(selected_shipping_option?.amount ? { selected_shipping_option } : {}),
        ...this.#buildAddressParams(
          cart,
          {
            billingAddress: payload.billingAddress,
            shippingAddress: payload.shippingAddress,
            billingSameAsShipping: payload.billingSameAsShipping,
          },
          options
        ),
        ...this.#buildCartDetails(cart),
      },
    }
  }

  static #buildAddressParams(
    cart: CartData,
    addressParams: { billingAddress: Address; shippingAddress: Address; billingSameAsShipping: boolean },
    options: { skipBillingAddress: boolean }
  ): ServerReqSummaryAddresses {
    const billing = addressParams.billingAddress
    const shipping = addressParams.shippingAddress
    const mode = globalThis.Checkout.store.checkout.mode.get()

    if (mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE) {
      return
    }

    let billingData, shippingData
    if (isSavedAddress(billing)) {
      billingData = {
        id: billing.id,
      }
    } else {
      billingData = {
        address_one: billing.address,
        address_two: billing.address_2,
        city: billing.city,
        state: billing.state,
        country: billing.country,
        postal_code: billing.zip,
      }
    }

    if (isSavedAddress(shipping)) {
      shippingData = {
        id: shipping.id,
        address_one: shipping.address,
        address_two: shipping.address_2,
        city: shipping.city,
        state: shipping.state,
        country: shipping.country,
        postal_code: shipping.zip,
      }
    } else {
      shippingData = {
        address_one: shipping.address,
        address_two: shipping.address_2,
        city: shipping.city,
        state: shipping.state,
        country: shipping.country,
        postal_code: shipping.zip,
      }
    }

    if (hasPhysicalProducts(cart)) {
      const billingSameAsShippingData = addressParams.billingSameAsShipping
      if (options.skipBillingAddress) {
        return { delivery_address: shippingData }
      }
      if (mode == globalThis.Checkout.CheckoutStates.OTO) {
        return {
          delivery_address: shippingData,
          billing_address: billingData,
        }
      }
      if (billingSameAsShippingData) {
        return {
          delivery_address: shippingData,
          billing_address: shippingData,
        }
      } else {
        return {
          delivery_address: shippingData,
          billing_address: billingData,
        }
      }
    } else {
      if (options.skipBillingAddress) return
      return { billing_address: billingData }
    }
  }

  static disableIsUpdatingRebilly(): void {
    globalThis.Checkout.store.isUpdatingRebilly.set(false)
  }
  static updateRebillyTransactionData(summary: SummaryData, cart: CheckoutCart): void {
    this.updateRebillyTransactionDataPromise(summary, cart).then(() => {
      this.disableIsUpdatingRebilly()
    })
  }
  static updateRebillyTransactionDataPromise(summary: SummaryData, cart: CheckoutCart): Promise<void> {
    const mode = globalThis.Checkout.store.checkout.mode.get()
    const shippingEnabled = globalThis.Checkout.utils.hasPhysicalProducts()

    const lineItems = summary?.line_items ?? []
    const RebillyFullyInitialized =
      globalThis.Checkout.store.payment.state.get() == globalThis.Checkout.PaymentStates.INITIALIZED
    if (mode == globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE || !lineItems.length || !RebillyFullyInitialized) {
      return Promise.resolve()
    }
    const transactionData = this.buildTransactionData(summary, cart, shippingEnabled)
    if (deepEqual(this.#lastRebillyUpdate, transactionData)) {
      return Promise.resolve()
    }
    return globalThis.Rebilly.update({ transactionData }).then(() => {
      this.disableIsUpdatingRebilly()
      this.#lastRebillyUpdate = transactionData
    })
  }

  static buildTransactionData(
    summaryData: SummaryData,
    cart: CheckoutCart,
    shippingEnabled: boolean
  ): CreateTransactionData {
    const { currency, line_items } = summaryData
    const lineItems = line_items.map(({ price, description }, index) => {
      let label
      if (line_items.length == cart.items.length) {
        const variantId = cart.items[index].variant_id
        const variant = globalThis.Checkout.variantsById[variantId]
        label = variant.name
      } else {
        label = description
      }
      return {
        label,
        amount: price,
      }
    })

    return {
      currency,
      amount: summaryData.subtotal.amount,
      label: 'Total Purchase',
      lineItems,
      requestShipping: shippingEnabled,
    }
  }
}
